<template>
  <div id="app">
    <SouthIndianChart />
  </div>
</template>

<script>
import SouthIndianChart from './components/SouthIndianChart.vue';

export default {
  name: 'App',
  components: {
    SouthIndianChart
  }
};
</script>